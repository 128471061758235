import 'zone.js/plugins/zone-error';

import { IEnvironment } from './base';

export const environment = {
  production: false,
  development: true,
  baseHref: '/',
  apiDomain: 'https://services.demo.panopticerp.com',
  apiUrl: '/gateway/api/v1/',
  reportServerUrl: 'ReportServer/Pages/ReportViewer.aspx',
  apiVersion: 'v1',
  serviceWorkerPath: './ngsw-worker.js',
  msalConfig: {
    auth: {
      clientId: '57697949-e2a6-40d7-820b-55c5b31348c7'
    }
  },
  apiConfig: {
    scopes: ['https://panopticdemo.onmicrosoft.com/1d9e5c73-aa68-476c-ab73-be69f0ad8f13/user_impersonation'],
    uri: 'https://app.demo.panopticerp.com/'
  },
  b2cPolicies: {
    names: {
      signUpSignIn: 'B2C_1A_SIGNUP_SIGNIN',
      resetPassword: 'B2C_1A_PASSWORDRESET',
      editProfile: 'b2c_1_edit_profile'
    },
    authorities: {
      signUpSignIn: {
        authority: 'https://panopticdemo.b2clogin.com/panopticdemo.onmicrosoft.com/B2C_1A_SIGNUP_SIGNIN'
      },
      resetPassword: {
        authority: 'https://panopticdemo.b2clogin.com/panopticdemo.onmicrosoft.com/B2C_1A_PASSWORDRESET'
      },
      editProfile: {
        authority: 'https://panopticdemo.b2clogin.com/panopticdemo.onmicrosoft.com/b2c_1_edit_profile'
      }
    },
    authorityDomain: 'panopticdemo.b2clogin.com'
  }
} as IEnvironment;
